import getHighlightColor from "../../../helpers/getHighlightColor"

const modifyAndSortRows = (yard, cars = [], selectedCars, model, showNotVisitedOnly) => {
    const rows = []
    const uniqueRows = new Set()
    const newCars = selectedCars === "New Cars" ? true : false
  
    cars.forEach((car) => {
      const rowNumber = car.row // Use the 'row' property from each car
      
      if (showNotVisitedOnly && car.visitedOn) return
      if (newCars && car.days > 10) return
      if (model !== "" && !car.model.toLowerCase().includes(model.toLowerCase())) return
  
      let rowObject
  
      if (!uniqueRows.has(rowNumber)) {
        uniqueRows.add(rowNumber)
  
        rowObject = {
          row: rowNumber,
          classNames: [getHighlightColor(car.days)],
        }
  
        if (rowNumber) rows.push(rowObject)
      } else {
        // Row has been processed before, find it in 'rows' array
        rowObject = rows.find((row) => row.row === rowNumber)
      }
  
      // Add "isHot" class if car.isHot is true
      if (car.isHot && !rowObject.classNames.includes("isHot")) {
        rowObject.classNames.push("isHot")
      }
    })
  
    sortRows(yard, rows)
    return rows
  }

function sortRows(yard, rows) {
  switch (yard) {
    case "OGDEN_PIC_A_PART":
      rows.sort((a, b) => a.row - b.row)
      break
    default:
      rows.sort((a, b) => a.row - b.row)
  }
}

export default modifyAndSortRows
