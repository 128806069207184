import React, { useEffect, useState } from "react"
import Styles from "./YardSearch.module.scss"
import ModelSearch from "./ModelSearch/ModelSearch"
import RowCarousel from "./RowCarousel/RowCarousel"
import sortCarsIntoYards from "./yardSearchFunctions/sortCarsIntoYards"
import createYardOptions from "./yardSearchFunctions/createYardOptions"
import modifyAndSortRows from "./yardSearchFunctions/modifyAndSortRows"
import RowResults from "./RowResults/RowResults"
import getYardCars from "./yardSearchFunctions/getYardCars"

const YardSearch = ({
  cars = [],
  populatePartsAndPulls,
  deletePartFromTicket,
  editPartFromTicket,
  updateManyCars,
}) => {
  const [filter, setFilter] = useState({
    selectedCars: "New Cars", //New Cars
    selectedMode: "Pulls", //Pulls
    model: "",
    showModelSearch: false,
    showNotVisitedOnly: true,
    selectedYard: localStorage.getItem("selectedYard") || "",
    selectedRowCars: [],
    yards: {},
    row: "",
  })

  useEffect(() => {
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        yards: sortCarsIntoYards(cars),
      }
    })
  }, [cars])

  const rows = Object.keys(filter.yards).length > 0
    ? modifyAndSortRows(
        filter.selectedYard,
        filter.yards[filter.selectedYard]?.cars,
        filter.selectedCars,
        filter.model,
        filter.showNotVisitedOnly
      )
    : []
  if (filter.yards && filter.selectedYard && filter.row) {
    const yardCars = getYardCars(
      filter.yards,
      filter.selectedYard,
      filter.row,
      filter.model
    )
    if (
      yardCars.length > 0 &&
      (filter.selectedRowCars.length === 0 ||
        yardCars[0].vin !== filter.selectedRowCars[0].vin)
    ) {
      populateRows(yardCars)
    }
  }
  //  {row: 1, classNames: ['oneDay', 'hotListCar']}

  //useEffect needs to sort the cars array and split it into yards.
  //The yard options need to be populated, next to each yardname will be a (2) representing how many days ago it was last updated

  //after selecting a yard, yardCars is populated with that yards cars. It also looks through all of the rows and saves them in 'rows'.
  //after clicking on a row, it gathers all of the cars from that row, sends them to the store for "populatePulls", AND a multi batch research parts.
  //The cars will then be sent back with the populated data. Those cars then get sent into RowResults, where the data is displayed.

  const handleCarsClick = () => {
    setFilter({
      ...filter,
      selectedCars:
        filter.selectedCars === "New Cars" ? "All Cars" : "New Cars",
    })
  }
  const handleModeClick = () => {
    setFilter({
      ...filter,
      selectedMode: filter.selectedMode === "Pulls" ? "Research" : "Pulls",
    })
  }

  const submitModelSearch = (model) => {
    setFilter({
      ...filter,
      model,
      showModelSearch: false,
    })
  }
  const cancelModelSearch = () => {
    setFilter({
      ...filter,
      showModelSearch: false,
    })
  }

  const setSelectedRow = (row) => {
    setFilter({
      ...filter,
      selectedRowCars: [],
      row,
    })

    //Next up is to implement the rowResults component that will use filter
    //To load the cars using the yardname, the yards, and the row. it will send the cars to the backend to get (Pulls, Parts, "isHotListCar")
    //isHotListCar should be true or false, if there is a true anywhere in the row, the className hasHotlistCar should be added to the classNames array
  }

  const handleYardSelect = (e) => {
    e.persist()
    localStorage.setItem("selectedYard", e.target.value)
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        row: "",
        selectedRowCars: [],
        selectedYard: e.target.value,
      }
    })
  }

  async function populateRows(cars) {
    let popCars = await populatePartsAndPulls(cars)
    setFilter((prevFilter) => {
      return { ...prevFilter, selectedRowCars: popCars }
    })
  }

  return (
    <div className={Styles["wrapper"]}>
      <div className={Styles["componentsWrapper"]}>
        <div className={Styles["yardControls"]}>
          <div className={Styles["yardSelect"]}>
            <i
              className={`${Styles.eye} material-icons`}
              onClick={() =>
                setFilter({
                  ...filter,
                  showNotVisitedOnly: !filter.showNotVisitedOnly,
                })
              }
              style={filter.showNotVisitedOnly ? { color: "#b64e29" } : {}}
            >
              visibility
            </i>
            <select onChange={handleYardSelect} value={filter.selectedYard}>
              {createYardOptions(filter.yards)}
            </select>
            <i
              onClick={() =>
                !filter.model
                  ? setFilter({ ...filter, showModelSearch: true })
                  : setFilter({ ...filter, model: "" })
              }
              className={`${Styles.search} material-icons`}
              style={filter.model ? { color: "#b64e29" } : {}}
            >
              search
            </i>
          </div>
          <div className={Styles["optionsToggle"]}>
            <p onClick={() => handleCarsClick()}>{filter.selectedCars}</p>
            <p onClick={() => handleModeClick()}>{filter.selectedMode}</p>
          </div>
          <RowCarousel
            rows={rows}
            setSelectedRow={setSelectedRow}
            selectedRow={filter.row}
          />
        </div>
        <RowResults
          editPartFromTicket={editPartFromTicket}
          deletePartFromTicket={deletePartFromTicket}
          cars={filter.selectedRowCars}
          researchOn={filter.selectedMode === "Research"}
          yard={filter.selectedYard}
          updateManyCars={updateManyCars}
          selectedRow={filter.row}
        />
      </div>
      {filter.showModelSearch ? (
        <ModelSearch
          submitModelSearch={submitModelSearch}
          cancelModelSearch={cancelModelSearch}
        />
      ) : (
        false
      )}
    </div>
  )
}

export default YardSearch
