import React from "react"
import Styles from "./RowResults.module.scss"
import VehicleCard from "./VehicleCard/VehicleCard"
import yardNames from "../../../helpers/yardNameEnums"

const RowResults = ({
  cars,
  researchOn,
  editPartFromTicket,
  deletePartFromTicket,
  updateManyCars,
  yard,
  selectedRow: row,
}) => {
  const currentYard = yardNames[yard]
  const vins = cars
    .map((car) => {
      if (+car.row === +row && car.yard === currentYard) {
        return car.vin
      }
      return null
    })
    .filter(Boolean)
    const allVisited = vins.every((vin) => {
      const car = cars.find((car) => car.vin === vin)
      return car && car.visitedOn // Check if visitedOn is not falsy
    })

  if (!researchOn) {
    cars = cars.filter((car) => car.pulls)
  }
  const carList = cars.map((x) => (
    <VehicleCard
      key={x.vin}
      editPartFromTicket={editPartFromTicket}
      deletePartFromTicket={deletePartFromTicket}
      researchOn={researchOn}
      {...x}
    />
  ))
  const handleVisitedClick = () => {
    
    const newValues = {
      visitedOn: allVisited ? "" : new Date().toLocaleDateString(),
    }

    // Call updateManyCars with the extracted VINs and the new values
    updateManyCars(newValues, vins)
  }
  return (
    <div className={Styles["wrapper"]}>
      {carList.length > 0 && (
        <div className={Styles["visited-container"]}>
          <p
            onClick={() => handleVisitedClick()}
            className={Styles["visited-button"]}
          >
            {allVisited ? "Row Visited" : "Row Not Visited"}
          </p>
        </div>
      )}
      {carList}
    </div>
  )
}

export default RowResults
